import React from "react";
import TopArea from "./components/TopArea/Default/index";
import TabMenu from "./components/TabMenu/Default/index";
import Footer from "./components/Footer/Default/index";
import useScreen from "./hooks/useScreen";
import TopAreaM from "./components/TopArea/Mobile/index";
import TabMenuM from "./components/TabMenu/Mobile/index";
import FooterM from "./components/Footer/Mobile/index";
import * as S from "./styles";
import Landing from "./components/Landing";
import { Maintenance } from "./components/Landing/Maintenance";

const OriginalApp = () => {
  const { isMobile } = useScreen();

  const 전화상담_신청 = () => {
    window.open("tel:1644-7407", "_blank", "noopener, noreferrer");
  };

  const 카톡문의_신청 = () => {
    window.open(
      "https://pf.kakao.com/_cMsSG/chat",
      "_blank",
      "noopener, noreferrer"
    );
  };
  const [activeTab, setActiveTab] = React.useState(1);
  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const 입점안내_신청 = () => {
    window.open("https://naver.me/5smLPms6", "_blank", "noopener, noreferrer");
  };

  const 빠른상담_신청 = () => {
    window.open("https://naver.me/GMWZyWMM", "_blank", "noopener, noreferrer");
  };

  const 회사소개_클릭 = () => {
    setActiveTab(7);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const currentURL = window.location.pathname;

  const is_랜딩페이지 = currentURL === "/tel7407";

  return (
    <>
      {isMobile ? (
        <>
          {is_랜딩페이지 && <Landing 전화상담_신청={전화상담_신청}></Landing>}
          {!is_랜딩페이지 && (
            <>
              <TopAreaM 빠른상담_신청={빠른상담_신청}></TopAreaM>
              <TabMenuM
                handleTabClick={handleTabClick}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              ></TabMenuM>
              <FooterM
                회사소개_클릭={회사소개_클릭}
                입점안내_신청={입점안내_신청}
              ></FooterM>
              <S.MoLinkContainer>
                <S.Button
                  type="button"
                  $backgroundColor="#008388"
                  $color="#fff"
                  onClick={전화상담_신청}
                >
                  <S.Wrapper>
                    <S.Icon src="/phonem.png" alt="전화_이미지" />
                    <S.ButtonTitle>전화상담</S.ButtonTitle>
                  </S.Wrapper>
                  <S.ButtonDesc>평일 오전 10시 ~ 18시</S.ButtonDesc>
                </S.Button>
                <S.Button
                  type="button"
                  $backgroundColor="#ffde2f"
                  $color="#3b1c1c"
                  onClick={카톡문의_신청}
                >
                  <S.Wrapper>
                    <S.Icon src="/kakao.png" alt="카카오톡_이미지" />
                    <S.ButtonTitle>카톡상담</S.ButtonTitle>
                  </S.Wrapper>
                  <S.ButtonDesc>친구추가 없이 1:1 상담</S.ButtonDesc>
                </S.Button>
              </S.MoLinkContainer>
            </>
          )}
        </>
      ) : (
        <>
          {is_랜딩페이지 && <Landing></Landing>}
          {!is_랜딩페이지 && (
            <>
              <TopArea 빠른상담_신청={빠른상담_신청}></TopArea>
              <TabMenu
                입점안내_신청={입점안내_신청}
                카톡문의_신청={카톡문의_신청}
                handleTabClick={handleTabClick}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                회사소개_클릭={회사소개_클릭}
                빠른상담_신청={빠른상담_신청}
              ></TabMenu>
              <Footer></Footer>
            </>
          )}
        </>
      )}
    </>
  );
};

const App = () => <Maintenance />;

export default App;
