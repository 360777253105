import React from "react";
import styled from "styled-components";

const Page = styled.main`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: #04b4bb;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 300px;
  border-radius: 12px;
  margin-top: 20px;
  padding: 12px;

  background-color: #04b4bb;
  filter: brightness(0.9);
`;

const Text = styled.p`
  margin: 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;

  color: #fff;
`;

export const Maintenance = () => {
  return (
    <Page>
      <img src="/da_logo.png" alt="로고_이미지" />

      <Details>
        <Text>다비교 홈페이지 수정 중입니다.</Text>
        <Text>문의: 1644-7407</Text>
      </Details>
    </Page>
  );
};
